.fc-direction-ltr {
  position: relative;
  z-index: 0;
}
.fc-theme-standard th {
  text-align: left;
  border: 0px;
  padding: 20px 0 10px;
}
.fc-theme-standard th a {
  color: var(--color-theme-green);
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  font-weight: 500;
}

.fc-toolbar-chunk {
  flex: 1;
}

.fc-header-toolbar .fc-toolbar-chunk:nth-child(2) > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc-checkInBtn-button {
  position: relative;
  color: var(--color-theme-primaryBlack) !important;
  background: var(--color-white) !important;
  border-radius: 22px !important;
  padding: 8px 22px 8px 55px !important;
  width: 145px;
  border: 1px solid var(--color-theme-orange) !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  height: 40px;
  text-transform: capitalize !important;
  box-shadow: 6px 5px 0px 0px rgba(255, 159, 36, 0.27);
  transition: all 0.5s;
}
.fc-checkInBtn-button:hover {
  color: var(--color-theme-orange) !important;
  box-shadow: unset;
}
.fc-checkInBtn-button.active,
.fc-checkInBtn-button:focus {
  border-color: var(--color-gray) !important;
  box-shadow: 6px 5px 0px 0px #39384229;
}
.fc-checkInBtn-button::before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background: url(../images/icons/checkin.svg) no-repeat;
  left: 28px;
  top: 10px;
}

.fc-checkInBtn-button:focus {
  box-shadow: 6px 5px 0px 0px rgba(255, 159, 36, 0.27) !important;
}

.fc .fc-daygrid-day.fc-day-today,
.fc .fc-daygrid-day:hover {
  background: linear-gradient(180deg, rgba(28, 152, 138, 0.17) 0%, rgba(255, 255, 255, 0) 100%);
}
.fc .fc-daygrid-day {
  cursor: pointer;
  transition: all 0.4s ease;
}
.fc .fc-daygrid-day.fc-day-other,
.fc .fc-daygrid-day.fc-day-sat,
.fc .fc-daygrid-day.fc-day-sun {
  background: unset;
  cursor: unset;
}
.fc .fc-daygrid-day-number {
  color: var(--color-theme-primaryBlack);
  text-decoration: none;
  font-size: 20px;
  padding-right: 18px;
}
.fc-theme-standard .fc-scrollgrid {
  border: 0px solid var(--fc-border-color);
}

.fc .fc-non-business {
  background: url(../images/off-day-lines.svg);
}

.fc .fc-toolbar-title {
  font-size: 16px;
  font-weight: 500;
}

td {
  position: relative;
}
.occupied-room {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.occupied-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  background: rgba(255, 159, 36, 0.27);
  border-radius: 12px 0px 0px 12px;
  padding: 6px 10px;
  border-left: 5px solid var(--color-theme-orange);
}
.occupied-room span {
  font-size: 32px;
  font-weight: 600;
  color: var(--color-lightGray);
}

.occupied-code {
  font-size: 10px;
  font-weight: 500;
}

.occupied-credit {
  width: 23px;
  height: 23px;
  background: var(--color-theme-green);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 10px;
  color: var(--color-white);
  flex: 0 0 23px;
}

.fc-icon-chevron-left,
.fc-icon-chevron-right {
  position: relative;
}
.fc-icon-chevron-left::before {
  content: "";
  background: url(../images/icons/left-arrowLine-green.svg) no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  left: -10px;
  top: 2px;
}
.fc-prev-button.fc-button-primary,
.fc-next-button.fc-button-primary {
  background: transparent !important;
  border: none;
  padding: 0;
}
.fc-prev-button.fc-button-primary:focus,
.fc-next-button.fc-button-primary:focus {
  box-shadow: none !important;
}

.fc-icon-chevron-right::before {
  content: "";
  background: url(../images/icons/right-arrrowLine.svg) no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  right: -10px;
  top: 2px;
}

.fc .fc-button-primary:disabled .fc-icon-chevron-left::before {
  background: url(../images/icons/left-arrowLine.svg) no-repeat;
}

#calendar .fc-button {
  /* box-shadow: none !important; */
}

/* .fc .fc-daygrid-body,
.fc-scrollgrid-sync-table {
  width: 1098px !important;
  height: 672px !important;
} */

.fc .fc-timegrid-slot-label {
  height: 64px;
  vertical-align: bottom;
  color: var(--color-lightGray);
  font-size: 12px;
  font-weight: 800;
}

.fc-timeGridWeek-view .fc-col-header thead tr:nth-child(1) th:nth-child(1) {
  visibility: hidden;
}

.custom-navigation-buttons {
  display: flex;
  justify-content: end;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.custom-prev-week-button,
.custom-next-week-button {
  border: none;
  background: none;
}

.custom-prev-week-button span,
.custom-next-week-button span {
  position: relative;
  background: #e8e8e8;
  display: block;
  height: 48px;
  width: 48px;
  border-radius: 100%;
}

.custom-prev-week-button span:hover,
.custom-next-week-button span:hover {
  background: var(--color-theme-green);
}

.custom-next-week-button span::before {
  content: "";
  position: absolute;
  background: url(../images/icons/white-arrow-right.svg) no-repeat;
  width: 32px;
  height: 32px;
  top: 8px;
  left: 6px;
  background-size: contain;
}

.custom-prev-week-button span::before {
  content: "";
  position: absolute;
  background: url(../images/icons/white-arrow-left.svg) no-repeat;
  width: 32px;
  height: 32px;
  top: 8px;
  left: 6px;
  background-size: contain;
}

.custom-navigation-buttons {
  height: 100%;
  background: linear-gradient(276deg, #f3f3f3 7.65%, rgba(255, 255, 255, 0) 89.71%);
}
.fc .fc-scrollgrid table {
  transition: width 0.6s ease;
}

span.fc-timegrid-axis-cushion.fc-scrollgrid-shrink-cushion.fc-scrollgrid-sync-inner {
  font-size: 0;
}
td.fc-timegrid-divider.fc-cell-shaded {
  padding: 0 !important;
  height: 0 !important;
  border: 0 !important;
}

tr.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid {
  height: 0 !important;
}

tr.fc-scrollgrid-section {
  height: 0 !important;
}
colgroup col {
  width: 164px !important;
}
.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  font-weight: 500;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  margin: 0;
  min-height: unset;
}
.fc-daygrid-day.percentage-added .percentage-data {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 64px;
  padding-bottom: 10px;
  font-size: 20px;
  line-height: 24px;
  color: var(--color-lightGray);
  font-weight: 600;
}
.fc .fc-scroller-harness table.fc-col-header {
  border: 0 !important;
}
/* ================= responsive ===================== */

@media (max-width: 1200px) {
  /* fullcalendar responsive */
  .fc .fc-daygrid-day-number {
    font-size: 14px;
    padding-right: 10px;
  }
  .occupied-room span {
    font-size: 18px;
  }
  .occupied-details {
    border-radius: 6px 0px 0px 6px;
    padding: 2px 10px;
  }
  .occupied-credit {
    width: 18px;
    height: 18px;
    font-size: 8px;
    flex: 0 0 18px;
  }
  .ui-datepicker-calendar tbody td {
    height: auto;
  }
  .ui-datepicker-calendar tbody td a,
  .ui-datepicker-calendar thead th,
  .ui-datepicker-title {
    font-size: 12px;
  }
  .ui-datepicker-header a.ui-datepicker-prev,
  .ui-datepicker-header a.ui-datepicker-next {
    background-size: 52%;
  }
  .ui-datepicker-header a.ui-corner-all {
    top: 2px;
  }
  .datepicker-position2 {
    top: 52px !important;
  }
}
