@media (max-width: 1200px) {
  .filter-options .filter-icon {
    width: 48px;
    height: 48px;
    border-radius: 100%;
  }

  .filter-options .filter-icon img {
    width: 21px;
  }

  .employee-list,
  .booking-date {
    min-width: 171px;
  }

  .employee-list .custom-dropdown-btn,
  .booking-date {
    height: 48px;
    border-radius: 60px;
  }
  .employee-list .custom-dropdown-btn span,
  .booking-date input::placeholder {
    font-size: 12px;
  }

  .employee-list .custom-dropdown-btn::after,
  .booking-date::after {
    width: 22px;
    height: 22px;
    border: 0px solid var(--color-theme-green);
  }
}

@media screen and (min-width: 992px) {
  .dropdwon-green:hover .dropdown-menu {
    /* display: block; */
    /* margin-top: 0; */
  }
}

@media (min-width: 1001px) {
  .tablet-screen-msg,
  .mobile-screen-msg {
    display: none;
  }
}

@media (max-width: 1000px) {
  .content-area,
  .mobile-screen-msg {
    display: none;
  }
  .tablet-screen-msg {
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    text-align: center;
    align-items: center;
    padding: 40px 25px;
    justify-content: center;
  }
  .rotate-msg h2 {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 0px;
    font-weight: 500;
    color: var(--color-theme-green);
  }

  .rotate-msg p {
    font-size: 20px;
    color: var(--color-gray);
  }
  .rotate-msg img {
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  .tablet-screen-msg {
    display: none;
  }
  .mobile-screen-msg {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    padding: 40px 25px;
    gap: 40px;
  }

  .app-msg h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: var(--color-theme-green);
    margin-bottom: 0;
  }

  .app-msg p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--color-gray);
  }
  .playstore {
    display: flex;
    flex-flow: column;
    gap: 20px;
  }
}
