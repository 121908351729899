/* fonts setup start*/
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Thin.eot");
  src: url("../fonts/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Thin.woff2") format("woff2"),
    url("../fonts/Roboto-Thin.woff") format("woff"), url("../fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.eot");
  src: url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff2") format("woff2"),
    url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Black.eot");
  src: url("../fonts/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Black.woff2") format("woff2"),
    url("../fonts/Roboto-Black.woff") format("woff"), url("../fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium.eot");
  src: url("../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Medium.woff2") format("woff2"),
    url("../fonts/Roboto-Medium.woff") format("woff"), url("../fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Light.eot");
  src: url("../fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Light.woff2") format("woff2"),
    url("../fonts/Roboto-Light.woff") format("woff"), url("../fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.eot");
  src: url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* font setup end */

/* common variables start*/

:root {
  /* fonts */
  --font-roboto: "Roboto", sans-serif;

  /* font sizes */
  --font-size-4xs: 9px;
  --font-size-3xs: 10px;
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --font-size-lg: 18px;

  /* Colors */
  --color-theme-green: #1c988a;
  --color-theme-orange: #ff9f24;
  --color-theme-primaryBlack: #2f2f2f;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-gray: #888888;
  --color-lightGray: #d2d2d2;
  --color-theme-bg: #f3f3f3;
  --color-lightWhite: #fefefe;
  --color-extralightGray: #e8e8e8;

  --color-green-opacity16: rgba(28, 152, 138, 0.16);
}
/* common variable end */

/* general styling start */

* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: var(--font-roboto);
  position: relative;
  background: var(--color-theme-bg);
}

p {
  font-size: var(--font-size-lg);
  line-height: 28px;
  margin: 0;
  font-weight: 400;
}

/* general styling end */

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-radius: 10px;
  border: 1px solid #dddbdb;
}
::-webkit-scrollbar-thumb {
  background: var(--color-theme-green);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #1ca08ce3;
}
