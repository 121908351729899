.container {
  max-width: 96%;
}
.header-inner {
  overflow: hidden;
}
/* Heading start*/
h1 {
  font-size: 36px;
  line-height: 46px;
  font-weight: 400;
  font-family: var(--font-roboto);
  color: var(--color-theme-primaryBlack);
  margin-bottom: 0 !important;
}
h2 {
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
  font-family: var(--font-roboto);
  color: var(--color-theme-primaryBlack);
  margin-bottom: 0 !important;
}
h3 {
  font-size: 16px;
  line-height: 36px;
  font-weight: 600;
  font-family: var(--font-roboto);
  color: var(--color-theme-primaryBlack);
  margin-bottom: 0 !important;
}
h4 {
  font-size: 14px;
  line-height: 28px;
  font-weight: 600;
  font-family: var(--font-roboto);
  color: var(--color-theme-primaryBlack);
  margin-bottom: 0 !important;
}
/* Heading end */

/* Initially hide the sidebar and header */
#sidebar-container,
#header-container {
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s ease, visibility 0s 0.1s; /* Delayed visibility to allow fade-out */
}
#header-container.loaded {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 99; /* Ensure it's above other content */
  background: #fff;
}

/* Show sidebar and header after loading */
#sidebar-container.loaded,
#header-container.loaded {
  opacity: 1;
  visibility: visible !important;
  transition: opacity 0.1s ease; /* Smooth transition when shown */
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent;
}
/* sidebar  */
.side-navbar {
  width: 80px;
  min-height: calc(100% - (90px + 95px));
  position: fixed;
  margin-left: 15px;
  transition: 0.5s;
  border-radius: 30px;
  background: var(--color-white);
  border: 1px solid var(--color-theme-green);
  flex-flow: column !important;
  z-index: 1;
  top: 95px;
}

.my-container {
  transition: 0.4s;
  margin-left: 100px;
  overflow-y: scroll;
  height: 100vh;
}

/* .active-nav {
  margin-left: 15px;
} */

.active-nav.side-navbar {
  width: 222px;
}

#accordian li {
  position: relative;
  width: 48px;
  border-radius: 32px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s ease;
  /* margin-bottom: 12px; */
}
#accordian li::after {
  position: absolute;
  content: "";
  background: var(--color-theme-green);
  top: 0%;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 32px;
  z-index: -1;
  transition: 250ms cubic-bezier(1, 0.2, 0.1, 1.2) all;
}
#accordian li:hover::after {
  opacity: 1;
  /* transition: all 0.4s ease; */
  top: 0;
  animation: gooeyEffect-1 250ms 1;
}

/* Gooey-animation for menu hover effect */
@keyframes gooeyEffect-1 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}
.active-nav #accordian li::after {
  background-color: unset;
}
#accordian li a span {
  /* font-size: 0px; */
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  display: none;
}

.active-nav #accordian li {
  position: relative;
  width: 100%;
  border-radius: 32px 0 0 32px;
  display: block;
  height: 48px;
  transition: 0.5s;
  /* z-index: 2; */
}
#accordian ul li.active:hover {
  background: unset !important;
}
.active-nav #accordian li a span {
  font-size: 12px;
  transition: 0.5s;
}

.active-nav .show-dropdown {
  padding-left: 26px;
  transition: 0.5s;
  padding-right: 6px;
}

.side-navbar .logo {
  padding-left: 0;
  padding-right: 0;
  width: 80px;
}
.side-navbar .logo img {
  width: 50px;
}

.active-nav.side-navbar a {
  width: 100%;
}
.active-nav.side-navbar .logo img {
  width: 100%;
  transition: all 0.6s ease;
}
.active-nav.side-navbar .logo {
  width: 70px;
  margin: auto;
}
.side-navbar .logo img {
  width: 100%;
}
.side-navbar .logo {
  width: 50px;
  margin: auto;
}
/* .userDropdown {
  display: flex;
  background: none;
  color: #000;
  border: none;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.6px;
}
.userImg {
  background: #752b2b;
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 16px;
}
.userDropdown:focus {
  box-shadow: none;
}

.userDropdown + .dropdown-menu li a {
  font-size: 15px;
} */
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
}
/* for main section */
.active-cont {
  margin-left: 260px;
}
.my-container .navbar {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
}
#menu-btn {
  background-color: var(--brown);
  /* margin-left: -34px; */
  padding: 10px;
  width: 35px;
  height: 35px;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.6s ease;
}

#menu-btn img {
  transform: rotate(180deg);
  transition: all 0.6s ease;
}

.active-nav #menu-btn img {
  transform: rotate(0deg);
}

.my-container > input,
.my-container > textarea {
  /* border-radius: 2rem; */
  /* padding: 2px 20px; */
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  font-size: 15px;
  color: #777;
}
.sub-menu {
  padding-left: 52px;
  /* margin-bottom: -10px; */
}
.sub-menu a {
  padding-left: 0 !important;
  text-decoration: none;
}
.sub-menu li {
  position: relative;
  list-style: none;
  padding-left: 22px;
  margin-bottom: 0 !important;
}
.sub-menu li::before {
  content: "\eb9a";
  position: absolute;
  left: 0;
  top: 10px;
}
.sub-menu li:hover a {
  color: var(--brown);
}

/* slidebar menu */

#accordian li {
  list-style-type: none;
  transition: all 0.6s ease;
}

#accordian ul li a {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 30px 12px 30px;
  text-decoration: none;
  height: 48px;
  transition: all 0.6s ease;
}
/* #accordian ul li.active a,
#accordian ul li:hover a,
#accordian ul li.active a,
#accordian ul li:hover a {
  transition: all 0.6s ease;
} */

.active-nav #accordian ul li.active a:before {
  content: "";
  position: absolute;
  /* background-color: transparent;
  right: -6px;
  top: -22px;
  height: 22px;
  width: 100%;
  border-bottom-right-radius: 40px;
  box-shadow: 35px 0px 0 0 var(--color-theme-green);
  transition: all 0.6s ease; */
  /* background-image: url("../images/btn-shape.svg"); */
  background-image: url(../images/btn-shape.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: calc(100% + 8px);
  height: 121px;
  left: 0;
  transition: all 0.6s ease;
  /* z-index: 0; */
}
/* .active-nav #accordian ul li.active a::after {
  content: "";
  position: absolute;
  background-color: transparent;
  right: -6px;
  bottom: -22px;
  height: 22px;
  width: 100%;
  border-top-right-radius: 40px;
  box-shadow: 35px 0px 0 0 var(--color-theme-green);
  transition: all 0.6s ease;
} */

.active-nav #accordian li::after {
  position: absolute;
  content: "";
  background: #f0f0f0;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
  border-radius: 30px;
  transition: all 0.6s ease;
  opacity: 0;
}

.active-nav #accordian li:hover::after {
  opacity: 1;
  animation: unset !important;
}
.active-nav #accordian li.active:hover::after {
  opacity: 0;
}

#accordian > ul > li > ul,
#accordian > ul > li > ul > li > ul,
#accordian > ul > li > ul > li > ul > li > ul,
#accordian > ul > li > ul > li > ul > li > ul > li > ul {
  display: none;
}

#accordian > ul > li.active > ul.show-dropdown,
#accordian > ul > li > ul > li.active > ul.show-dropdown,
#accordian > ul > li > ul > li > ul > li.active > ul.show-dropdown,
#accordian > ul > li > ul > li > ul > li > ul > li.active > ul.show-dropdown {
  display: block;
}

#accordian > ul > li > ul,
#accordian > ul > li > ul > li > ul,
#accordian > ul > li > ul > li > ul > li > ul,
#accordian > ul > li > ul > li > ul > li > ul > li > ul {
  padding-left: 20px;
}

#accordian a:not(:only-child):after {
  position: absolute;
  right: 20px;
  top: 12px;
  font-size: 25px;
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  transition: 0.5s;
  content: "\ea17";
}

#accordian .active > a:not(:only-child):after {
  transform: rotate(180deg);
}

.show-dropdown {
  padding-left: 15px;
}
.show-dropdown i {
  margin-right: 10px;
  width: 20px;
  font-size: 20px;
}

.show-dropdown li a {
  position: relative;
}

.show-dropdown li.active > a {
  color: var(--white) !important;
}
/* .show-dropdown li.active > a::before {
  width: 94%;
}
.show-dropdown li a::before {
  content: "";
  background: var(--brown);
  left: 0;
  width: 0%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  border-radius: 0 8px 8px 0;
  transition: all 0.6s ease;
} */

.sub-menu li {
  padding-left: 42px;
}

.sub-menu li::before {
  left: 13px;
}

ul.sub-menu.show-dropdown li.active a {
  color: var(--brown) !important ;
}
ul.sub-menu.show-dropdown li.active a::before {
  width: 0;
}

/* header start */

.header {
  /* top: -35px; */
  /* padding: 10px 0px; */
  position: -ms-page;
  height: 80;
  z-index: 9999;
  background: var(--color-white);
  width: 100%;
  align-items: center;
  display: flex;
  flex-flow: row;
}

.notification-info a {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1.5px solid var(--color-theme-green);
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification-info a:hover {
  background: var(--color-theme-green);
}
.notification-info a:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7450%) hue-rotate(334deg) brightness(104%) contrast(101%);
}

.profileImg img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1.5px solid var(--color-theme-green);
  display: flex;
  align-items: center;
  justify-content: center;
}

.orange-dot {
  border: 3px solid var(--color-theme-orange);
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 100%;
  background: var(--color-white);
  top: 10px;
  right: 11px;
}
/* header end */

.content-area {
  /* margin: 0 15px; */
}

.pt-96 {
  padding-top: 10px;
}
.pb-96 {
  padding-bottom: 96px;
}
/* side bar styling */
.menu-style li a {
  font-size: 12px;
  font-weight: 500;
  color: var(--color-theme-green);
}

.active-nav .menu-style li a img {
  margin-right: 12px;
}
.menu-style li a img {
  margin-right: 0;
  width: 14px;
  height: 14px;
}

/* font styling */
.f12 {
  font-size: 12px;
  font-weight: 500;
}
.max-w-full .d-flex div {
  flex: 1;
  justify-content: flex-start;
}
/* buttons */
.main-btn {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 45px;
  border-radius: 40px;
  border: 1px solid;
  font-size: 12px;
  line-height: 26px;
  font-weight: 500;
  transition: all 0.5s;
}

.fill-green-btn {
  background-color: var(--color-theme-green);
  color: var(--color-white);
  border-color: var(--color-theme-green);
}
.fill-green-btn:hover {
  background-color: var(--color-green-opacity16);
  border-color: var(--color-green-opacity16);
  color: var(--color-theme-green);
}
.fill-green-btn:active {
  background-color: var(--color-white);
  color: var(--color-theme-green);
}
.outline-gray-btn {
  background-color: transparent;
  color: var(--color-gray);
  border-color: var(--color-gray);
}
.outline-gray-btn:hover {
  background-color: var(--color-extralightGray);
  border-color: var(--color-lightGray);
}
.outline-gray-btn:active {
  background-color: var(--color-gray);
  color: var(--color-white);
  border-color: var(--color-gray);
}
.icon-with-text {
  position: relative;
  overflow: hidden;
  padding: 12px 28px 12px 18px;
  display: flex;
  column-gap: 15px;
  border: 1px solid;
  border-radius: 40px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: var(--color-theme-green);
  align-items: center;
  width: fit-content;
}

.green-outline-with-icon {
  border-radius: 40px;
  border: 1px solid var(--color-theme-green);
  max-width: 120px;
  min-height: 46px;
  color: var(--color-theme-green);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
  transition: all 0.6s ease;
  font-size: 14px;
  font-weight: 600;
}

.green-outline-with-icon:hover,
.icon-with-text:hover {
  background: var(--color-green-opacity16);
  border: 1px solid var(--color-green-opacity16);
  color: var(--color-theme-green);
  box-shadow: 0 0.5em 0.5em -0.4em var(--color-green-opacity16);
}

.green-outline-with-icon.selected {
  background: var(--color-theme-green);
  color: var(--color-white);
}

.green-outline-with-icon.selected img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7450%) hue-rotate(334deg) brightness(104%) contrast(101%);
}

.orange-outline-with-icon {
  border-radius: 40px;
  border: 1px solid var(--color-theme-orange);
  padding: 10px 13px;
  color: var(--color-theme-green);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-decoration: none;
  transition: all 0.6s ease;
}

.orange-outline-with-icon span {
  color: var(--color-gray);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.orange-outline-with-icon:hover {
  background: var(--color-theme-orange);
}
.orange-outline-with-icon:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7450%) hue-rotate(334deg) brightness(104%) contrast(101%);
}

.orange-outline-with-icon:hover span {
  color: var(--color-white);
}

.checkInBtn-button {
  text-transform: capitalize;
  font-size: 10px;
  line-height: 10px;
  padding: 9px 35px;
  box-shadow: 4px 5px 0px 0px rgba(255, 159, 36, 0.27);
  border-color: var(--color-theme-orange);
  color: var(--color-theme-primaryBlack);
  display: inline-block;
  width: fit-content;
}
.checkInBtn-button:hover {
  box-shadow: unset;
  color: var(--color-theme-orange);
}
.checkInBtn-button:focus,
.checkInBtn-button:active {
  box-shadow: 4px 5px 0px 0px #dfdfe1;
  border-color: var(--color-gray);
}
.gray-outline-with-icon {
  border-radius: 40px;
  border: 1px solid var(--color-gray);
  padding: 13px 22px;
  color: var(--color-theme-green);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  text-decoration: none;
  transition: all 0.6s ease;
  font-size: 12px;
  line-height: 14px;
  width: fit-content;
}

.gray-outline-with-icon span {
  color: var(--color-gray);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.gray-outline-with-icon:hover {
  background: var(--color-extralightGray);
}

.green-filled-with-icon span {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  object-fit: contain;
  background: var(--color-theme-green);
  display: flex;
  align-items: center;
  justify-content: center;
}
.green-filled-with-icon {
  border-radius: 40px;
  border: 1px solid var(--color-theme-green);
  width: max-content;
  height: 48px;
  padding: 10px 22px 10px 7px;
  color: var(--color-theme-green);
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 8px;
  text-decoration: none;
  transition: all 0.6s ease;
  font-size: 12px;
}

.green-filled-with-icon:hover {
  background: #1c988a29;
  border: 1px solid #1c988a29;
  color: var(--color-theme-green);
}

.green-filled-with-icon2 {
  border-radius: 40px;
  border: 1px solid var(--color-theme-green);
  /* max-width: 87px;
  min-height: 30px; */
  background: var(--color-white);
  color: var(--color-theme-green);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
  transition: all 0.4s ease;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  padding: 5px 18px 5px 15px;
  width: fit-content;
}

.green-filled-with-icon2:hover {
  background: var(--color-theme-green);
  /* border: 1px solid var(--color-green-opacity16); */
  color: var(--color-white);
  /* box-shadow: 0 0.5em 0.5em -0.4em var(--color-green-opacity16); */
}

.green-filled-with-icon2 span img {
  filter: brightness(0) saturate(100%) invert(46%) sepia(78%) saturate(415%) hue-rotate(124deg) brightness(90%) contrast(91%);
}
.green-filled-with-icon2:hover span img {
  filter: unset !important;
}
.fill-white-icon {
  column-gap: 10px;
  padding: 14px 40px;
}
.fill-white-icon img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7450%) hue-rotate(334deg) brightness(104%) contrast(101%);
}

.fill-white-icon:hover img {
  filter: unset;
}
.fill-red-btn {
  color: var(--color-red);
  background-color: transparent;
  column-gap: 12px;
  padding: 15px 18px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
}
.fill-red-btn:hover {
  background-color: var(--color-lightred);
  color: var(--color-red);
}
.fill-red-btn:active,
.fill-red-btn:focus {
  background-color: var(--color-red);
  color: var(--color-white);
}
.fill-red-btn:active img,
.fill-red-btn:focus img {
  filter: brightness(100);
}
.cancel-btn {
  padding: 20px 23px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: var(--color-gray);
  border-color: var(--color-gray);
  background-color: transparent;
}
.cancel-btn:hover {
  background-color: var(--color-extralightGray);
  border-color: var(--color-lightGray);
}
.cancel-btn:focus,
.cancel-btn:active {
  background-color: var(--color-gray);
  color: var(--color-white);
  border-color: var(--color-gray);
}
.disable,
.fc-checkInBtn-button.disable {
  background-color: transparent !important;
  color: var(--color-lightGray) !important;
  border-color: var(--color-lightGray) !important;
  pointer-events: none !important;
  box-shadow: unset !important;
}
.disable span {
  color: var(--color-lightGray);
}
.disable span img {
  filter: grayscale(1);
}
.green-filled-with-icon.disable span {
  background: var(--color-lightGray);
}
.disable img {
  filter: brightness(1) saturate(0) invert(0) sepia(100%) saturate(0) hue-rotate(0) brightness(130%) contrast(100%);
}
a.green-filled-with-icon2.disable span img {
  filter: contrast(0.5);
}
.fill-red-btn.disable img {
  filter: brightness(1) saturate(0) invert(0) sepia(100%) saturate(0) hue-rotate(0) brightness(180%) contrast(100%);
}
.fc-checkInBtn-button.disable::before {
  filter: grayscale(150%);
}
.max-w-full p {
  flex: 2;
  font-size: 16px;
}

.max-w-full div {
  column-gap: 25px;
}

/* buttons end */

.show-dropdown {
  /* overflow: hidden; */
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

#accordian li.active,
#accordian li.active:hover {
  background: var(--color-theme-green);
}

#accordian li.active a img,
#accordian li.active:hover a img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7450%) hue-rotate(334deg) brightness(104%) contrast(101%);
}

#accordian li.active a span,
#accordian li.active:hover a span {
  color: var(--color-white) !important;
}
/* #accordian li:hover a span,
#accordian li.active:hover a span {
  font-size: 12px;
  position: absolute;
  right: -110px;
  color: var(--color-theme-green) !important;
  background: #fff;
  padding: 2px 5px;
  border-radius: 15px;
  text-align: center;
  display: block !important;
  width: 112px;
} */
#accordian li a span.tooltip,
#accordian li a::after {
  position: absolute;
  top: -20px;
  left: 80px;
  width: max-content;
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.03);
  padding: 6px 12px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 500;
  opacity: 0;
  pointer-events: none;
  transition: 0s;
  color: var(--color-theme-green) !important;
}
#accordian li a::after {
  content: attr(data-tooltip);
}
#accordian li:hover a span.tooltip,
#accordian li:hover a::after {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
  display: block !important;
}
#accordian li.active:hover a span.tooltip,
#accordian li.active:hover a::after {
  color: var(--color-theme-green) !important;
}
.active-nav #accordian li a span.tooltip,
.active-nav #accordian li a::after {
  display: none !important;
}
.active-nav .show-dropdown {
  overflow: hidden;
}
.active-nav #accordian li a span,
.active-nav #accordian li.active a span {
  position: relative;
  background-color: unset;
  width: unset;
  right: unset;
  padding: 0;
  text-align: left;
  border-radius: unset;
  flex: 1 0 auto;
  opacity: 1;
  pointer-events: auto;
  transition: 0.4s;
  display: block;
}
.active-nav #accordian li.active a span {
  color: #fff !important;
}
#accordian li:hover {
  /* background: var(--color-theme-green); */
}
/* .active-nav #accordian li:hover a span {
  color: var(--color-gray);
} */
.active-nav #accordian li:hover a img {
  filter: unset;
}
.active-nav #accordian li.active:hover a img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7450%) hue-rotate(334deg) brightness(104%) contrast(101%);
}
#accordian li:hover a img {
  /* filter: brightness(0) saturate(100%) invert(57%) sepia(10%) saturate(23%) hue-rotate(340deg) brightness(91%) contrast(93%); */
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7450%) hue-rotate(334deg) brightness(104%) contrast(101%);
}

.open-close a {
  margin-left: auto;
  position: relative;
  right: -15px;
}

/* on click to close sidebar */

/* #accordian li {
  width: 48px;
  border-radius: 32px !important;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#accordian li a span {
  font-size: 0px;
}
.menu-style li a img {
  margin-right: 0;
}

.side-navbar {
  width: 80px;
}
.show-dropdown {
  padding-left: 15px;
} */
.logout-btn {
  font-size: 0;
  gap: 0;
  max-width: 48px;
}
.active-nav .logout-btn {
  font-size: 12px;
  gap: 10px;
  max-width: 120px;
}

/* ======================================================== */

/* header components start */

.greeting-title p {
  color: var(--color-theme-green);
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}

.dropdwon-green ul.dropdown-menu {
  background: var(--color-theme-green);
}

.profile-dropdown li:not(:last-child) {
  margin-bottom: 15px;
}
.profile-dropdown {
  padding: 20px;
  border-radius: 12px;
  margin-top: 15px !important;
  margin-left: 15px !important;
  animation: 0.6s slideup;
}
@keyframes slideup {
  0% {
    margin-top: -50px;
    visibility: hidden;
    opacity: 0;
  }

  100% {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
  }
}
.profile-dropdown li a {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0;
}
.profile-dropdown li a span.withIcon {
  width: 24px;
  height: 24px;
  border: 1px solid var(--color-white);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-dropdown li a span.withTitle {
  font-size: 12px;
  color: var(--color-white);
  font-weight: 400;
}

.profile-dropdown::before {
  content: "";
  background: url(../images/icons/triangle.svg);
  width: 27px;
  position: absolute;
  top: -11px;
  height: 12px;
  right: 21px;
}
.profile-dropdown li:hover .withIcon {
  background: var(--color-white);
}
.profile-dropdown li:hover .withIcon img {
  filter: brightness(0) saturate(100%) invert(45%) sepia(90%) saturate(356%) hue-rotate(124deg) brightness(92%) contrast(95%);
}
/* header components end */

/* ======================================================== */

/* ======= all modal/offcanvas start ===================== */

/* hedaer notification start */
.notification-panel.offcanvas-end {
  top: 85px;
  border-left: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 368px;
}
.notification-panel .offcanvas-body {
  padding: 0;
}
.notification-panel .offcanvas-header {
  padding: 29px 35px 29px 25px;
}
.notification-panel .offcanvas-header h5 {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-theme-green);
  margin-bottom: 0;
}
.notification-panel .btn-close {
  width: auto;
  height: auto;
  background: none;
  opacity: 1;
  padding: 0 !important;
}
.notify-details {
  padding-left: 0;
  margin-bottom: 0;
}
.notify-details li {
  list-style: none;
  display: flex;
  gap: 10px;
  align-items: center;
}
.notify-details li span {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: var(--color-theme-green);
  flex: 0 0 36px;
  object-fit: contain;
}
.notify-details li span img {
  border-radius: 100%;
}

.notification-list .accordion-button {
  padding: 12px;
  background: var(--color-theme-bg);
  box-shadow: none;
  cursor: pointer;
}
.notification-list .accordion-item {
  border: none;
  margin-bottom: 12px;
}

.notify-content h3 {
  font-size: var(--font-size-xs);
  line-height: 18px;
  color: var(--color-theme-primaryBlack);
  margin-bottom: 0;
}
.notify-content p {
  font-size: var(--font-size-3xs);
  font-weight: 500;
  line-height: 14px;
  color: var(--color-gray);
}

.notification-list .accordion-button::after {
  background: url(../images/icons/right-arrow.svg);
  width: 25px;
  height: 24px;
  object-fit: contain;
}
.notification-list .accordion-button:not(.collapsed)::after {
  transform: rotate(90deg);
}
.notification-list .accordion-body {
  background: var(--color-theme-bg);
  margin-top: 5px;
}
.notification-list .accordion-body p {
  font-size: var(--font-size-3xs);
  line-height: 18px;
  font-weight: 500;
  color: var(--color-gray);
  padding-left: 38px;
}
.notify-footer {
  display: flex;
  justify-content: space-between;
  padding: 19px 12px;
  flex-wrap: wrap;
  border-top: 1px solid #d2d2d2;
}

.powerline span {
  font-size: var(--font-size-3xs);
  font-weight: 500;
  line-height: 15px;
  color: var(--color-gray);
  margin-left: 5px;
}

.view-link a {
  color: var(--color-theme-green);
  font-size: var(--font-size-sm);
  font-weight: 500;
  text-decoration: none;
}
.view-link a:hover {
  color: var(--color-theme-primaryBlack);
}
/* hedaer notification end */

/* ======= all modal/offcanvas end ===================== */

/* company dropdwon style */
.custom-dropdown-btn {
  width: 100%;
  background: var(--color-white);
  color: var(--color-gray);
  text-align: left;
  padding: 10px 36px 10px 20px;
  height: 40px;
  border-radius: 40px;
  border: 1px solid var(--color-lightGray);
  font-size: var(--font-size-base);
}
.custom-dropdown-btn span {
  overflow: hidden;
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.custom-dropdown-btn::after {
  content: "";
  /* font-family: "Font Awesome 6 Free"; */
  /* font-weight: bold; */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 9px;
  border: none;
  width: 24px;
  height: 24px;
  background: url(../images/icons/up-down-arrow.svg) var(--color-gray) no-repeat 7px -15px;
  border-radius: 100%;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-align: center;
  transition: all 0.5s;
}
.custom-dropdown-btn.show::after {
  background-position: 7px 8px;
}
.custom-dropdown.show {
  border-radius: 12px;
  background: var(--color-white);
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.07);
  top: 130% !important;
  left: 0 !important;
  min-width: 90%;
  display: flex !important;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  transform: none !important;
  border: none !important;
  overflow: hidden;
  max-width: 96%;
  transition: none;
  height: max-content;
  right: 0 !important;
  margin: 0 auto !important;
  padding: 10px 10px;
  box-shadow: 32px 25px 12px 0px rgba(136, 134, 134, 0), 21px 16px 11px 0px rgba(136, 134, 134, 0.01), 12px 9px 9px 0px rgba(136, 134, 134, 0.05), 5px 4px 7px 0px rgba(136, 134, 134, 0.09), 1px 1px 4px 0px rgba(136, 134, 134, 0.1);
  z-index: 1;
}
.custom-dropdown.show li {
  position: relative;
  width: 100%;
  display: flex !important;
}

.custom-dropdown-btn:focus {
  box-shadow: 0 0 0 5px rgb(136 136 136 / 10%) !important;
  border: 1px solid var(--color-lightGray);
}

.custom-dropdown.show li.selected a {
  /* color: var(--color-theme-green) !important; */
}
.custom-dropdown.show li a {
  color: var(--color-theme-primaryBlack);
  font-size: var(--font-size-sm);
  white-space: pre-wrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 30px;
}

.custom-dropdown.show li::after {
  position: absolute;
  content: "";
  border: 1px solid var(--color-theme-green);
  width: 10px;
  height: 10px;
  top: 32%;
  left: 10px;
  border-radius: 100%;
  background: var(--color-white);
}

.custom-dropdown.show li.selected::after {
  position: absolute;
  content: "";
  border: 1px solid var(--color-white);
  width: 10px;
  height: 10px;
  top: 35%;
  left: 10px;
  border-radius: 100%;
  background: var(--color-theme-green);
  box-shadow: 0 0 0 5px rgb(28 152 138 / 44%);
}
/* #emp-dropdown.custom-dropdown.show li.selected::after {
  border: 0 !important;
  box-shadow: 0 0 0 2px rgb(28 152 138 / 44%);
} */
button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
.custom-dropdown.show::-webkit-scrollbar {
  width: 6px;
}
.custom-dropdown.show::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-radius: 10px;
  border: 1px solid #dddbdb;
}
.custom-dropdown.show::-webkit-scrollbar-thumb {
  background: var(--color-theme-green);
  border-radius: 10px;
}
.custom-dropdown.show::-webkit-scrollbar-thumb:hover {
  background: #1ca08ce3;
}
/* custom dropdown end */

#desk-dropdown-btn {
  min-width: 164px;
}
#area-dropdown-btn {
  min-width: 235px;
}

/* Calender switcher start */
.calendar-switcher {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.calendar-switcher input[type="checkbox"].toggle {
  opacity: 0;
  position: absolute;
  left: -99999px;
}
.calendar-switcher input[type="checkbox"].toggle + label {
  height: 40px;
  line-height: 40px;
  background-color: var(--color-theme-green);
  padding: 0px 16px;
  border-radius: 25px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  -moz-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  -webkit-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
  /* -moz-box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.5); */
  outline: unset;
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
  -moz-box-shadow: unset !important;
}
.calendar-switcher input[type="checkbox"].toggle + label:before,
.calendar-switcher input[type="checkbox"].toggle + label:hover:before {
  content: " ";
  position: absolute;
  top: 5px;
  left: 7px;
  width: 30px;
  height: 30px;
  background: #fff;
  z-index: 2;
  -moz-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  -webkit-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
  -moz-border-radius: 14px;
  -webkit-border-radius: 14px;
  border-radius: 100%;
}
.calendar-switcher input[type="checkbox"].toggle + label .off,
.calendar-switcher input[type="checkbox"].toggle + label .on {
  color: #fff;
  font-size: 12px;
  width: 44px;
  text-align: center;
}
.calendar-switcher input[type="checkbox"].toggle + label .off {
  margin-left: 39px;
  display: inline-block;
}
.calendar-switcher input[type="checkbox"].toggle + label .on {
  display: none;
}
.calendar-switcher input[type="checkbox"].toggle:checked + label .off {
  display: none;
}
.calendar-switcher input[type="checkbox"].toggle:checked + label .on {
  margin-right: 39px;
  display: inline-block;
}
.calendar-switcher input[type="checkbox"].toggle:checked + label,
.calendar-switcher input[type="checkbox"].toggle:focus:checked + label {
  background-color: var(--color-theme-green);
}
.calendar-switcher input[type="checkbox"].toggle:checked + label:before,
.calendar-switcher input[type="checkbox"].toggle:checked + label:hover:before,
.calendar-switcher input[type="checkbox"].toggle:focus:checked + label:before,
.calendar-switcher input[type="checkbox"].toggle:focus:checked + label:hover:before {
  background-position: 0 0;
  top: 5px;
  left: 100%;
  margin-left: -38px;
}

/* filter start */
.filter-options .filter-icon {
  border-radius: 15px;
  border: 1px solid var(--color-lightGray);
  background: var(--color-lightWhite);
  width: 52px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.6s ease;
}

.filter-options .filter-icon:hover img {
  filter: brightness(0) saturate(100%) invert(57%) sepia(10%) saturate(23%) hue-rotate(340deg) brightness(91%) contrast(93%);
}

.employee-list .custom-dropdown-btn {
  height: 60px;
  border-radius: 15px;
}

.sub-dropdown-name {
  font-size: 10px;
  font-weight: 500;
  color: var(--color-gray);
  line-height: 14px;
}
.employee-list .custom-dropdown-btn span {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-theme-green);
}
.employee-list .custom-dropdown-btn::after {
  background-image: url(../images/icons/hover-btn-admin.svg);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 9px center;
  transition: all 0.5s;
  width: 32px;
  height: 32px;
  border: 1px solid var(--color-theme-green);
  border-radius: 100%;
}
.employee-list .custom-dropdown-btn.show::after,
.employee-list .custom-dropdown-btn:hover::after {
  /* filter: brightness(0) saturate(100%) invert(44%) sepia(71%) saturate(438%) hue-rotate(124deg) brightness(97%) contrast(94%); */
  background-color: var(--color-theme-green);
  background-position: -9px center;
}

.employee-list .custom-dropdown.show {
  top: 115% !important;
}

.employee-list,
.booking-date {
  min-width: 284px;
}

.booking-date {
  border: 1px solid var(--color-lightGray);
  background: var(--color-white);
  height: 60px;
  border-radius: 15px;
  padding-left: 20px;
  padding-right: 10px;
  position: relative;
}

.date-choose {
  position: relative;
  width: 100%;
}
.date-choose::after {
  background: url(../images/icons/colleagues.svg) no-repeat center center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--color-theme-green);
  border-radius: 100%;
  top: 10%;
  content: "";
  position: absolute;
  right: 0;
}
.date-choose span {
  font-size: 14px;
  font-weight: 700;
  color: var(--color-theme-green);
}

/* ================================================================ */
/* Custom DatePicker start */
/* .wrapper {
  max-width: 18rem;
  padding: 0 0.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4rem;
} */
.booking-date label {
  font-size: 10px;
  font-weight: 500;
  color: var(--color-gray);
  width: 100%;
  line-height: 14px;
  position: relative;
  cursor: pointer;
}
.booking-date input {
  display: block;
  border: none;
  margin-top: 0;
  /* height: 22px; */
  font-size: 14px;
  width: 100%;
  font-weight: 400;
  cursor: pointer;
  caret-color: transparent;
  color: var(--color-theme-green);
}

.booking-date::after {
  content: "";
  background-image: url(../images/icons/calendar-hover-btn.svg);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 8px center;
  transition: all 0.5s;
  width: 32px;
  height: 32px;
  border: 1px solid var(--color-theme-green);
  border-radius: 100%;
  top: 24%;
  position: absolute;
  right: 10px;
}
.booking-date:hover::after {
  background-color: var(--color-theme-green);
  background-position: -16px center;
}
.booking-date input::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-theme-green);
}
.booking-date input:focus {
  outline: none;
}

.datepicker-position {
  left: 0 !important;
  top: 65px !important;
  width: 115%;
  z-index: 2 !important;
  position: absolute !important;
}

.datepicker-position2 {
  width: auto;
  left: 0 !important;
  top: 65px !important;
  z-index: 2 !important;
  position: absolute !important;
}

#ui-datepicker-div {
  display: none;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 10px 0 0 0;
}
.booking-date table {
  border-collapse: collapse;
  border-spacing: 0;
}
.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: var(--color-theme-green);
}
.ui-datepicker-calendar tbody td {
  width: 46px;
  text-align: center;
  padding: 0;
  border: 1px solid #e8e8e8;
  height: 44px;
}
.ui-datepicker-calendar tbody td a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  line-height: 2rem;
  transition: 0.3s all;
  color: var(--color-theme-primaryBlack);
  font-size: 12px;
  text-decoration: none;
  width: 100%;
  height: 100%;
}
.ui-datepicker-calendar tbody td a:hover {
  background-color: #e0f2f1;
}
.ui-datepicker-calendar tbody td a.ui-state-active {
  background-color: var(--color-theme-green);
  color: white;
}
.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s all;
}
.ui-datepicker-header a.ui-corner-all:hover {
  /* background-color: #eceff1; */
}
.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
  /* background: url("../images/icons/left-arrowLine-green.svg"); */
  background: url(../images/icons/left-arrowLine-green.svg);
  background-repeat: no-repeat;
  background-position: 50%;
}
.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
  background: url(../images/icons/right-arrrowLine.svg);
  background-repeat: no-repeat;
  background-position: 50%;
}
.ui-datepicker-header a > span {
  display: none;
}
.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 0.25rem;
  color: var(--color-theme-green);
}
.ui-datepicker-week-col {
  color: #78909c;
  font-weight: 400;
  font-size: 0.75rem;
}

.hidden-datepicker {
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

/* Custom DatePicker end */
/* ================================================================ */

/* filter popup start */
.popup-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  color: var(--color-theme-green);
}
.popup-subtitle {
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: var(--color-theme-primaryBlack);
}
.popup-subtitle span {
  color: var(--color-theme-green);
}
.date-size {
  font-size: 36px;
  font-weight: 400;
  line-height: 40px;
  color: var(--color-theme-primaryBlack);
  border: none;
  width: 40px;
  position: relative;
}
.date-size::before {
  content: "";
  background: var(--color-theme-green);
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
}
.month-year-size {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-gray);
  border: none;
  text-transform: uppercase;
}
.custom-datepicker input:focus-visible {
  outline: none;
}

.modal-custom-width {
  max-width: 395px;
}

.modal-custom-width-long {
  max-width: 671px;
}

.close-modal {
  background: var(--color-theme-green);
  position: absolute;
  right: 0;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.6s ease;
}

.close-modal:hover {
  background: var(--color-theme-primaryBlack);
}

.modal-radius {
  border-radius: 40px;
  padding: 0 12px 20px 12px;
}
#vehicle-type-modal .modal-content {
  border-color: var(--color-theme-green);
}
.modal-backdrop {
  background: var(--color-extralightGray);
}
.popup-inner-title {
  font-size: 12px;
  font-weight: 600;
  color: var(--color-theme-primaryBlack);
  margin-bottom: 12px;
  margin-top: 12px;
}

.inline-btn-tags {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 20px;
}

.tags-btn button {
  border-radius: 50px;
  border: 1px solid var(--color-theme-green);
  background: var(--color-lightWhite);
  width: 102.028px;
  height: 40px;
  font-size: 12px;
  font-weight: 500;
  line-height: 26px;
  color: var(--color-theme-green);
  transition: all 0.6s ease;
}
.tags-btn button.selected,
.tags-btn button:hover {
  color: var(--color-white);
  background: var(--color-theme-green);
}

.divider-line {
  width: 100%;
  height: 1px;
  background: var(--color-lightGray);
  margin: 30px 0;
}
.divider-vertical {
  width: 2px;
  background: var(--color-theme-green);
  border-radius: 10px;
}
.approval-action-btn .main-btn.fill-green-btn {
  padding: 6px 41px;
}
/* filter popup end */

/* confirmation popup start */
.confirmation-content {
  padding: 65px 0 50px;
}
#confirmationModal .modal-custom-width {
  max-width: 330px;
}
.popup-img {
  padding: 50px 0;
}
/* confirmation popup end */

/* Meeting2 popup start */
.unique-content .unique-detail {
  column-gap: 14px;
}
.unique-date {
  font-size: 36px;
  line-height: 36px;
  color: var(--color-theme-primaryBlack);
}
.unique-date span {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray);
}
.unique-content .unique-detail p {
  font-size: 16px;
  color: var(--color-gray);
}
.unique-content .unique-detail img {
  height: 16px;
  width: 16px;
  object-fit: contain;
}
.meeting-form {
  padding: 54px 0 48px;
}
/* Meeting2 popup end */

/* Form desing css start */

.field-wrap input,
.field-wrap textarea,
.field-wrap select {
  position: relative;
  height: 48px;
  padding: 11px 40px 11px 16px;
  border: 1px solid var(--color-lightGray);
  color: var(--color-theme-green);
  border-radius: 12px;
  font-size: 12px;
  line-height: 26px;
  font-family: var(--font-roboto);
  font-weight: 500;
}
.field-wrap .input-icon {
  position: absolute;
  right: 16px;
  top: 12px;
}
.field-inner label {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
  font-weight: 600;
  color: var(--color-theme-primaryBlack);
}
.field-inner {
  margin-bottom: 20px;
}
.needs-validation .invalid-feedback,
.needs-validation.was-validated .form-control:invalid {
  color: var(--color-red);
  border-color: var(--color-red);
}
.needs-validation.was-validated .invalid-feedback {
  margin: 6px 0 0 16px;
}
.meeting-form .meeting-form-inner {
  max-height: 408px;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  overflow-y: auto !important;
  margin-bottom: 20px;
  padding: 5px;
}
.field-inner textarea.form-control {
  height: 97px;
  resize: unset;
}
.field-select .form-select {
  background-image: unset !important;
}
.field-select::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 6px;
  background-image: url(../images/icons/down-arrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
  top: 21px;
  right: 20px;
  z-index: 1;
}

.custom-select {
  position: relative;
  width: 100%;
}

.selected-option {
  padding: 11px 40px 11px 16px;
  border: 1px solid var(--color-lightGray);
  border-radius: 12px;
  cursor: pointer;
  background-color: var(--color-white);
  color: var(--color-gray);
  font-family: var(--font-roboto);
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
}

.options {
  display: none;
  border: 1px solid var(--color-lightGray);
  border-top: none;
  position: absolute;
  width: 100%;
  background: var(--color-white);
  border-radius: 12px;
  z-index: 10;
  padding: 10px 16px;
}

.options div {
  padding: 3px 0px;
  cursor: pointer;
  color: var(--color-gray);
  font-family: var(--font-roboto);
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
}

/* Form design css end */

/* Slider css */

.swiper-pagination-bullet {
  background-color: var(--color-lightGray); /* Default color */
  opacity: 100 !important;
}
.swiper-pagination-bullet-active ~ .swiper-pagination-bullet {
  background-color: var(--color-lightGray); /* Next bullets remain unchanged */
}

/* For the bullets before the active one */
.swiper-pagination-bullets .swiper-pagination-bullet {
  background-color: var(--color-theme-green); /* Set color for all previous bullets */
}

.swiper-pagination-bullet-active ~ .swiper-pagination-bullet {
  background-color: var(--color-lightGray); /* Next bullets remain unchanged */
}

/* This rule ensures that previous bullets have a  background */
.swiper-pagination-bullets .swiper-pagination-bullet-active {
  background-color: var(--color-theme-green); /* Color for the active bullet */
}

#confirmationModal .modal-content {
  border: 0;
}
/* Slider css end */

/* current date popup */

.selected-current-date {
  display: flex;
  align-items: center;
  gap: 35px;
}
.selected-current-date span:nth-child(1) {
  color: var(--color-theme-primaryBlack);
  font-size: 36px;
  font-weight: 400;
  position: relative;
}

.selected-current-date span:nth-child(1):after {
  content: "";
  position: absolute;
  width: 2px;
  height: 38px;
  right: 0;
  background: var(--color-theme-green);
  margin-right: -20px;
  top: 8px;
  border-radius: 10px;
}
.selected-current-date span:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-gray);
  text-transform: uppercase;
}

.date-with-credit .orange-outline-with-icon {
  width: 60px;
}
.date-with-credit .orange-outline-with-icon span {
  font-size: 16px;
}

.min-height-56 {
  min-height: 56px;
}

/* custom timepicker style */

.time-selection .input-group-addon {
  position: absolute;
  width: 100%;
  height: 100%;
}

.time-selection {
  border-radius: 12px;
  border: 1px solid var(--color-lightGray);
  padding: 20px;
}

.time-selection label {
  font-size: 12px;
  font-weight: 500;
  line-height: 26px;
}
.time-selection input::placeholder,
.time-selection input:focus {
  color: var(--color-lightGray);
  font-size: 24px;
  box-shadow: none;
}
.time-selection input {
  border: none;
  padding: 0;
  color: var(--color-theme-green);
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  width: 100%;
}

.time-selection input:focus {
  outline: none;
}

.time-selection .row .rightArrow {
  position: relative;
}
.time-selection .row .rightArrow:before {
  content: "";
  position: absolute;
  background: url(../images/icons/right-black-arrow.svg) no-repeat;
  width: 25px;
  height: 25px;
  top: 18px;
  right: 0;
  z-index: 1;
}

.time-options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 12px;
  position: absolute;
  width: 94%;
  background: var(--color-white);
  left: 0;
  border-radius: 12px;
  border: 1px solid var(--color-lightGray);
  padding: 25px 12px;
  top: 10px;
  right: 0;
  margin: auto;
}

.time-option {
  border: 1px solid var(--color-lightGray);
  width: 100%;
  height: 47px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: var(--color-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  transition: all 0.6s ease;
  cursor: pointer;
}

.time-option.disable,
.time-option.disable:hover {
  color: var(--color-gray);
  background: #e8e8e8;
  cursor: not-allowed;
}
.time-option.selected,
.time-option:hover {
  background: var(--color-theme-green);
  color: var(--color-white);
}

.name-date-header .day-name {
  color: var(--color-theme-green);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.name-date-header .day-date {
  font-size: 16px;
  font-weight: 400;
  color: var(--color-theme-primaryBlack);
}

.pl20 {
  padding-left: 20px;
}
.pl40 {
  padding-left: 40px;
}

/* =========== setting ==================== */

/* setting layout and form start */

.setting-options .nav-tabs {
  border-bottom: 1px solid var(--color-lightGray);
  margin: 0 12px;
}

.setting-options .nav-tabs li {
  flex: 1;
}
.setting-options .nav-tabs li button.active {
  background: transparent;
  color: var(--color-theme-green);
  border: none;
}
.setting-options .nav-tabs li button {
  position: relative;
  width: 100%;
  text-align: left;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  padding: 0 0 25px;
  color: var(--color-gray);
}

.setting-options .nav-tabs li:nth-child(2) button {
  text-align: center;
}
.setting-options .nav-tabs li:nth-child(3) button {
  text-align: right;
}

.form-elements label {
  font-size: 12px;
  font-weight: 500;
  color: var(--color-gray);
  line-height: 26px;
  margin-bottom: 5px;
}

.form-elements input:not(.form-check-input),
.form-elements select {
  border-radius: 4px;
  border: 1px solid var(--color-lightGray);
  height: 54px;
  background: var(--color-lightWhite);
  padding-left: 20px;
  font-size: 12px;
  color: var(--color-theme-green);
  font-weight: 500;
}
.form-elements input:not(.form-check-input):focus,
.form-elements select:focus {
  box-shadow: 0 0 0 0.25rem rgb(28 152 138 / 16%);
  border: 1px solid var(--color-theme-green);
}

.form-elements .select-wrapper:after {
  content: "";
  background: url(../images/icons/green-down-arrow.svg) no-repeat center center;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.form-elements select option:hover {
  background: var(--color-theme-green); /* Text color for better visibility */
}

.form-switch {
  padding-left: 0;
  display: flex;
  align-items: center;
  height: 24px;
}
.form-check .form-check-input {
  float: inline-end;
  width: 40px;
  height: 24px;
  margin: unset;
}
.form-switch label {
  text-align: right;
  width: 100%;
  padding-right: 55px;
  margin-bottom: 0;
  font-weight: 400;
  flex: 1;
}

.switcher-list {
  display: flex;
  align-items: end;
  flex-flow: column;
  gap: 20px;
  margin-top: 50px;
}

.form-check-input:checked {
  background-color: var(--color-theme-green);
  border-color: var(--color-theme-green);
}

.form-switch .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(28 152 138 / 16%);
  border: 1px solid var(--color-theme-green);
  /* background-image: url(../images/icons/green-dot.svg); */
}

.gray-outline-btn {
  border: 1px solid var(--color-gray) !important;
  color: var(--color-gray) !important;
}
.gray-with-icon {
  font-size: 12px !important;
  font-weight: 500;
  color: var(--color-gray) !important;
  text-decoration: unset !important;
}
.gray-outline-btn:hover {
  background: var(--color-extralightGray) !important;
}

.bottom-action-btn {
  border-top: 1px solid var(--color-lightGray);
  margin-top: 40px;
  padding: 40px 0;
}
.bottom-action-btn button:nth-child(1) {
  /* min-width: 134px; */
  height: 48px;
  font-size: 14px;
  line-height: 16px;
  padding: 16px 45px;
  width: fit-content;
}
.bottom-action-btn button:nth-child(2) {
  /* min-width: 200px; */
  height: 48px;
  padding: 16px 56px;
  width: fit-content;
  font-size: 14px;
  line-height: 16px;
}
.height-wrapper {
  height: 586px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 10px;
}
/* setting layout and form end */

/* theme tabs style start */
.nav-tabs {
  position: relative;
  border-bottom: none;
}

/* .nav-tabs::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--color-theme-green);
  transition: left 0.3s ease, width 0.3s ease;
} */

.nav-tabs .nav-link {
  border: none;
  overflow: hidden;
}

.nav-tabs .nav-link.active {
  font-weight: bold;
}
.setting-options .nav-tabs li button::before {
  position: absolute;
  content: "";
  bottom: 0;
  height: 2px;
  background-color: var(--color-theme-green);
  width: 100%;
  transition: all 0.4s ease;
  left: -100%;
  opacity: 0;
}
.setting-options .nav-tabs li .nav-link.active::before,
.setting-options .nav-tabs li .nav-link:hover {
  opacity: 1;
  left: 0;
}
/* theme tabs style end */

/* rect-dropdown  styles start */
.rect-dropdown {
  margin-bottom: 10px;
}
.rect-dropdown button {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  height: 54px;
}
.rect-dropdown button:focus {
  box-shadow: none !important;
}
.rect-dropdown .custom-dropdown.show {
  position: relative !important;
  border-radius: 0 !important;
  width: 100% !important;
  box-shadow: none !important;
  margin: 10px auto !important;
  border-radius: 4px !important;
  border: 1px solid var(--color-theme-green) !important;
}

.rect-dropdown .custom-dropdown-btn::after {
  background: url(../images/icons/arrow-down.svg) no-repeat center;
}

.vehicle-options,
.size-type-options {
  margin-top: 32px;
}

.rect-dropdown-without-arrow {
  width: calc(100% - 16px);
}

.rect-dropdown-without-arrow .custom-dropdown-btn::after {
  display: none;
}
/* rect-dropdown  styles end */

/* radio type button start */
div.radio-with-Icon {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 15px;
}

div.radio-with-Icon p.radioOption-Item label {
  color: #de1831;
  cursor: pointer;
  opacity: 0.8;
  transition: none;
  font-size: 13px;
  text-align: center;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  text-align: center;
}

div.radio-with-Icon p.radioOption-Item label img {
  width: 50px;
  height: 50px;
  box-shadow: 0px 6.667px 13.333px 0px rgba(49, 50, 51, 0.1);
  padding: 8px;
  border-radius: 12px;
  object-fit: contain;
}

div.radio-with-Icon p.radioOption-Item label::after,
div.radio-with-Icon p.radioOption-Item label:after,
div.radio-with-Icon p.radioOption-Item label::before,
div.radio-with-Icon p.radioOption-Item label:before {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
}
div.radio-with-Icon p.radioOption-Item label i.fa {
  display: block;
  font-size: 50px;
}
div.radio-with-Icon p.radioOption-Item input[type="radio"] {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
}
div.radio-with-Icon p.radioOption-Item input[type="radio"]:active ~ label {
  opacity: 1;
}
div.radio-with-Icon p.radioOption-Item input[type="radio"]:checked ~ label {
  opacity: 1;
  border: none;
  color: #fff;
}

div.radio-with-Icon p.radioOption-Item label:hover img,
div.radio-with-Icon p.radioOption-Item label:focus img,
div.radio-with-Icon p.radioOption-Item label:active img,
div.radio-with-Icon p.radioOption-Item input[type="radio"]:checked ~ label img {
  filter: brightness(0) saturate(100%) invert(44%) sepia(99%) saturate(339%) hue-rotate(124deg) brightness(92%) contrast(92%);
}

div.radio-with-Icon p.radioOption-Item input[type="radio"]:checked ~ label .type-info,
div.radio-with-Icon p.radioOption-Item label:hover .type-info,
div.radio-with-Icon p.radioOption-Item label:focus .type-info,
div.radio-with-Icon p.radioOption-Item label:active .type-info {
  color: var(--color-theme-green);
}

div.radio-with-Icon p.radioOption-Item input[type="radio"]:checked ~ label .type-info span,
div.radio-with-Icon p.radioOption-Item label:hover .type-info span,
div.radio-with-Icon p.radioOption-Item label:focus .type-info span,
div.radio-with-Icon p.radioOption-Item label:active .type-info span {
  color: var(--color-theme-primaryBlack);
}

span.type-info {
  font-size: 12px;
  line-height: 18px;
  color: var(--color-theme-primaryBlack);
  padding-top: 10px;
  font-weight: 500;
}
.size-info {
  font-size: 9px;
  font-weight: 400;
  line-height: 18px;
  color: #888888;
}
/* radio type button end */

/* ==== view more btn ====== */

.view-mode a {
  width: 126px;
  font-size: 12px;
  height: 40px;
  min-height: 40px;
}

/* new passowrd styling */
.progress-bar {
  width: 100%;
  height: 3.5px;
  background-color: #e0e0e0;
  border-radius: 10px;
}
#strength-bar {
  height: 100%;
  width: 0;
  border-radius: 10px;
  background-color: #d80027;
}

#strength-label {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.password-strength {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin: 10px 0;
}

.password-requirements p {
  color: var(--color-gray);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 6px;
  line-height: 20px;
}

.password-requirements .icon img {
  width: 12px;
  height: 12px;
  object-fit: contain;
  margin-right: 6px;
}

.psw-bind img {
  position: absolute;
  top: 14px;
  right: 14px;
}

/* boxes */
.role-boxes {
  border-radius: 24px;
  border: 1px solid var(--color-extralightGray);
  background: var(--color-lightWhite);
  padding: 30px;
}
.role-icon {
  display: flex;
  width: 33px;
  height: 33px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: var(--color-theme-green);
  border-radius: 32px;
  margin-left: auto;
}
.role-boxes:hover .role-icon {
  background-color: var(--color-theme-orange);
}
.emp-name h3 {
  color: var(--color-theme-primaryBlack);
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.12px;
}

.emp-place-info .selected-current-date {
  gap: 24px;
}

.emp-place-info .selected-current-date span:nth-child(1) {
  padding: 4px 0;
  font-size: 10px;
}

.emp-place-info .selected-current-date span:nth-child(1):after {
  width: 1px;
  margin-right: -14px;
  height: 100%;
  top: 0;
}
.emp-place-info .selected-current-date span:nth-child(2) {
  font-size: 10px;
}
.selected-current-date span span:nth-child(1):after {
  display: none;
}
.color-green {
  color: var(--color-theme-green) !important;
}

.role-area {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 25px 0 10px;
}

.role-area p {
  color: var(--color-theme-green);
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.14px;
}

.green-outline-badge {
  width: 34px;
  height: 18px;
  border: 1px solid var(--color-theme-green);
  border-radius: 10px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.12px;
  text-align: center;
  text-decoration: none;
  color: var(--color-theme-green);
}

.plr-40 {
  padding: 0 40px;
}

.role-area-wrapper {
  position: relative;
  cursor: pointer;
}

.list-container {
  display: none; /* Initially hidden */
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 1;
  border-radius: 12px;
}

.list-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding: 12px 0;
}

.list-container li {
  display: flex;
  column-gap: 12px;
  align-items: center;
  padding: 6px 12px;
  color: var(--color-theme-primaryBlack);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.role-area-wrapper:hover .list-container,
.role-area-wrapper.active .list-container {
  display: block; /* Show on hover or when active */
}

/* table styling */
.table-container {
  display: flex;
  align-items: center;
}

.table-container td.hidden,
.table-container th.hidden {
  display: none;
}

.table-details {
  width: 100%;
}

.table-container thead th {
  color: var(--color-theme-primaryBlack);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.05px;
  padding: 20px;
}
.table-container thead tr {
  border-bottom: 1px solid var(--color-theme-green);
}

.table-container tbody tr td {
  padding: 13px 20px;
  color: var(--color-gray);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.05px;
}
.table-container #dynamicTable tbody tr td {
  height: 56px;
}
.table-container tbody tr:not(:last-child) td {
  border-bottom: 1px solid var(--color-lightGray);
}

/* table data styling */
.name-with-profile,
.name-with-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.name-with-profile span {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  border: 1px solid;
  border-radius: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.name-with-profile span img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid;
}
.name-with-profile h3,
.name-with-icon h3 {
  color: var(--color-gray);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.05px;
  margin-bottom: 0;
}

.module-title {
  color: var(--color-theme-primaryBlack);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.05px;
  margin-bottom: 0;
}

.action-arrows {
  position: absolute;
  right: 0;
  background: linear-gradient(276deg, #f3f3f3 7.65%, rgba(255, 255, 255, 0) 89.71%);
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  gap: 15px;
}

.name-with-icon img {
  height: 24px;
  width: 24px;
  object-fit: contain;
}

.total-count {
  color: var(--color-gray);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 0;
}

.map-details {
  mix-blend-mode: multiply;
}
/* Example CSS for fade-in effect */
#footer-container {
  opacity: 0;
  transition: opacity 0.5s ease-in;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

#footer-container.loaded {
  opacity: 1;
}
#footer-container p,
#footer-container ul li.nav-item a {
  color: var(--color-gray);
  font-weight: 400;
  font-size: 14px;
}
#footer-container p.copy-right {
  color: var(--color-theme-green);
  font-size: 16px;
  font-weight: 600;
}

.vehicle-details .height-wrapper::-webkit-scrollbar {
  width: 8px;
}
.vehicle-details .height-wrapper::-webkit-scrollbar-track {
  background: #fefefe;
  border-radius: 10px;
  border: 1px solid var(--color-lightGray);
}
.vehicle-details .height-wrapper::-webkit-scrollbar-thumb {
  background: var(--color-lightGray);
  border-radius: 10px;
}
.credit-info:hover .credit-info-inner {
  transform: unset;
  visibility: visible;
  opacity: 1;
  display: block;
}

.credit-info .credit-info-inner {
  top: 85px !important;
  border: unset !important;
  width: 368px !important;
  height: 440px !important;
  padding: 22px 34px 45px !important;
  background-color: #fefefe !important;
  transform: translateX(-100px);
  visibility: hidden;
  transform: translateX(100%);
  position: fixed;
  right: 0;
  display: block;
  opacity: 0;
  transition: all 0.3s;
}

.credit-info,
.notification-info,
.profile-info {
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;
}
.credit-balance-info {
  padding: 0 16px;
  margin: 32px 0 0 0;
  border-bottom: 1px solid var(--color-lightGray);
}
.credit-balance-btn {
  margin: 13px 0 23px;
  padding: 10px 30px;
  font-size: 12px;
  line-height: 16px;
  background: #eaeaea;
  border-radius: 25px;
  color: var(--color-gray);
  font-weight: 500;
  font-family: var(--font-roboto);
}
.credit-balance-btn a {
  text-decoration: unset;
  color: var(--color-theme-green);
}
.profile-location p {
  font-size: 12px;
  line-height: 14px;
  color: var(--color-gray);
}
.profile-location img {
  margin-right: 6px;
}
.profile-name p {
  font-size: 16px;
  line-height: 26px;
  margin: 5px 0;
  color: var(--color-theme-primaryBlack);
}

.profile-img img {
  width: 74px;
  height: 74px;
  border-radius: 100%;
}

.credit-balance h6 {
  font-size: 24px;
  line-height: 26px;
  color: var(--color-theme-primaryBlack);
  font-family: var(--font-roboto);
}

.credit-balance p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: var(--color-theme-green);
  font-family: var(--font-roboto);
}
.credit-details-lists {
  padding: 0 20px;
  margin: 24px 0 0 0;
}

.credit-details-lists .list-card {
  display: flex;
  column-gap: 15px;
  margin-bottom: 10px;
  align-items: center;
}

.credit-details-lists .list-card p {
  font-size: 12px;
  line-height: 14px;
  color: var(--color-gray);
  font-weight: 500;
}
.sidebar-collapsed .active-cont {
  margin-left: 100px;
}
.selected-data-color span {
  color: var(--color-theme-green);
}
.selected-data-color img {
  filter: brightness(0) saturate(100%) invert(46%) sepia(78%) saturate(415%) hue-rotate(124deg) brightness(90%) contrast(91%);
}
#dynamicTable a {
  text-decoration: unset;
}
/* booking-pop-up css */
#bookingsPopup.modal.show .modal-dialog {
  transform: none;
  left: unset !important;
  right: 0px !important;
}
#bookingsPopup.modal .modal-dialog {
  transform: unset;
  margin-right: 284px;
}
.bookings-popup .modal-custom-width {
  max-width: 340px;
}
.zone_wrapper {
  padding-top: 10px;
}
.zone_wrapper .zone-location-details h5 {
  font-size: 16px;
  line-height: 40px;
  font-weight: 600;
  margin: 0;
  color: var(--color-theme-green);
}
.zone-location-details {
  margin-bottom: 20px;
}
.location-wrap p {
  font-size: 12px;
  line-height: 14px;
  margin-left: 7px;
}
.zone-checkin {
  margin-bottom: 14px;
  position: relative;
}

.zone-checkin img {
  border-radius: 20px;
  opacity: 60%;
}
.zone-checkin .zone-btn {
  position: absolute;
  bottom: 16px;
  margin: auto;
  width: 100%;
  display: block;
  text-align: center;
  left: 0;
}

.zone-date-time .zone-date,
.zone-date-time .zone-time {
  padding: 10px 15px;
  border: 1px solid var(--color-gray);
  border-radius: 50px;
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  color: var(--color-gray);
  display: flex;
  align-items: center;
}

.zone-date-time .zone-date span,
.zone-date-time .zone-time span {
  margin-left: 14px;
}
.zone-content {
  padding: 26px 0 14px;
}

.zone-content h4 {
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  font-family: var(--font-roboto);
  color: var(--color-gray);
}
.add-read-more.show-less-content .second-section,
.add-read-more.show-less-content .read-less {
  display: none;
}
.add-read-more.show-more-content .read-more {
  display: none;
}
.add-read-more .read-more,
.add-read-more .read-less {
  font-weight: bold;
  margin-left: 2px;
  color: var(--color-theme-green);
  cursor: pointer;
  /* text-transform: capitalize; */
}
.add-read-more {
  font-size: 10px;
  line-height: 15px;
  color: var(--color-gray);
  font-weight: 500;
  width: 100%;
  margin: 0 auto;
}
.zone-tag {
  display: flex;
  column-gap: 24px;
  flex-wrap: wrap;
  row-gap: 6px;
}
.zone-tag .zone-tag-btn {
  padding: 8px 11px;
  display: flex;
  column-gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid var(--color-theme-green);
  border-radius: 50px;
}
.zone-tag .zone-tag-btn p {
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  color: var(--color-gray);
}
.zone-organizer-list {
  padding: 30px 0 0 0;
}
.zone-organizer-list .list-card {
  margin-bottom: 21px;
}
.zone-organizer-list .list-card span {
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: var(--color-gray);
}
.zone-organizer-list .list-card p {
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: var(--color-theme-green);
  margin-left: 12px;
}
.list-img-wrap {
  display: flex;
  margin-left: 24px;
  flex-direction: row-reverse;
}

.list-img-wrap img {
  margin: 0 0 0 -10px;
  border-radius: 50px;
  border: 1px solid #fff;
}
.red-outline-btn {
  border-color: #ef3826;
  color: #ef3826;
}
.bookings-popup .tags-btn button.red-outline-btn {
  padding: 7px 45px;
  width: unset;
  margin: 0;
}
.red-outline-btn:hover {
  background: #fff5f5;
}
.bookings-popup .tags-btn button.selected {
  padding: 7px 33px;
  width: unset;
  margin: 0;
}
.bookings-popup .modal-footer {
  border: 0;
  padding: 38px 18px 30px;
}
.booking-info {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #e8e8e8;
  top: 0;
  left: 0;
}
.booking-info .booking-details {
  display: flex;
  align-content: flex-end;
  flex-wrap: wrap;
  padding: 10px;
  height: 100%;
}

.booking-info .booking-details .booking-credit {
  font-size: 14px;
  line-height: 20px;
  margin: -2px 0 0 2px;
  font-weight: 700;
  color: var(--color-theme-green);
}

/* Successful message notification css */

.successful-message-wrapper,
.error-message-wrapper {
  position: fixed;
  right: 0;
  padding: 16px;
  width: 312px;
  border-radius: 8px 0px 0px 8px;
  display: flex;
  z-index: 99;
}
.successful-message-wrapper {
  bottom: 249px;
  background: #fff;
}
.error-message-wrapper {
  bottom: 150px;
  background-color: #e6d0d0;
}

.success-content h6,
.error-content h6 {
  font-size: var(--font-size-sm);
  list-style: 14px;
  font-weight: 600;
  font-family: var(--font-roboto);
  margin-bottom: 4px;
}
.success-content h6,
.success-content p {
  color: var(--color-theme-green);
}

.success-content p,
.error-content p {
  font-size: var(--font-size-xs);
  line-height: 12px;
  font-weight: 400;
  font-family: var(--font-roboto);
}
.error-content p,
.error-content h6 {
  color: var(--color-red);
}

/* checkin popup css */
#checkinModal .fc-checkInBtn-button::before {
  content: unset !important;
}
#checkinModal .fc-checkInBtn-button {
  padding: 9px 31px !important;
  font-size: 10px !important;
  line-height: 10px !important;
  min-width: 111px;
}
#checkinModal .table tbody {
  background: #f9fafc;
  border-color: var(--color-extralightGray);
}
#checkinModal .table {
  margin: 36px 0;
}
#checkinModal .table thead th,
#checkinModal .table tbody tr td {
  padding: 20px;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  border-width: 0px 1px 1px 0px;
  border-color: var(--color-extralightGray);
}
#checkinModal .table tbody tr td:nth-child(2) {
  padding: 20px 20px 20px 8px !important;
}
#checkinModal .table tbody tr td:nth-child(3n) {
  padding: 14px 20px 14px 18px;
}
#checkinModal .table tbody tr {
  border: none !important;
}

#checkinModal .table thead {
  border-top: 1px solid var(--color-extralightGray);
}

.checkin-info {
  margin: 0 -28px;
}
#checkinModal .location-info {
  position: relative;
  display: flex;
  column-gap: 14px;
  font-size: 16px;
  line-height: 33px;
  font-weight: 500;
  color: var(--color-theme-primaryBlack);
}
#checkinModal .popup-title {
  color: var(--color-theme-primaryBlack);
}
#checkinModal .table thead th {
  background-color: #fff;
}

#checkinModal .modal-content {
  border: 1px solid #e8e8e8;
}

#checkinModal .date-with-credit h5 {
  font-size: 36px;
  line-height: 36px;
}

#checkinModal .date-with-credit h5 span {
  font-size: 14px;
  line-height: 20px;
  display: block;
  color: #888;
  font-weight: 400;
}
#checkinModal .location-info::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 60px;
  background: #ff9f24;
  top: -17px;
  left: -13px;
}
#calendar .fc-checkInBtn-button {
  background: #ff9f24a8 !important;
  border: none !important;
}

#calendar .fc-checkInBtn-button::before {
  background-position: center -27px;
}
#calendar .fc-checkInBtn-button:hover {
  background-color: #fff !important;
}

#calendar .fc-checkInBtn-button:hover::before {
  background-position: center 0px;
}
#checkinModal .location-info p {
  font-size: 16px;
  line-height: 33px;
  color: var(--color-theme-primaryBlack);
}
/* preloader effect */
/* pre-loader with load time draw and fill-out */
/* #preloader .svg-path {
  fill: none;
  stroke: #1c988a;
  stroke-width: 2;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 2s ease-in-out forwards, fillCycle 4s ease-in-out 1s forwards;
}

@keyframes draw {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fillCycle {
  0% {
    fill: none;
  }
  50% {
    fill: #1c988a;
  }
  100% {
    fill: #1c988a;
  }
  50% {
    fill: #1c988a;
  }
  100% {
    fill: #1c988a;
  }
} */
